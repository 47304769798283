<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="overflow-auto">
        <h2 class="p-5 pl-0 text-xl border-b border-gray-300 mb-5">{{ $t('calloff_overview') }}</h2>
        <router-view @setLoading="loading = $event"/>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
  }),
};
</script>

